.style2 {
    width: 100%;
}

.style2 .ql-container {
    min-height: 10rem;
    max-height: 14rem;
    overflow-y: auto;
    font-size: .9rem;
    border: none;
}

.style2 .ql-toolbar {
    background-color: white;
}

.style2 .ql-editor {
    margin: 10px 15px;
    padding: 0px;
}